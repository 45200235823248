<template>
  <div class="container">
    <div class="left-sidebar">
      <div class="content">
        <div class="item" v-for="(item, index) in sidebarList" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="text" v-for="child in item.children" :key="child.text">
            <span class="text-title">{{ child.text }}: </span>
            <span class="text-value">{{ child.value }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right-content">
      <div class="top-module" v-if="isRepeatEvery">
        <a-calendar v-model="calendarValue" @select="handleSelect">
          <template slot="dateCellRender" slot-scope="value">
            <div v-if="taskDatetimeList.includes(moment(value).format('YYYY-MM-DD'))">
              <div v-for="(item, index) in handleDate(value)" :key="index">
                {{ item.memberName }}
              </div>
            </div>
          </template>
        </a-calendar>
      </div>
      <div class="bottom-module">
        <div style="text-align: right">
          <span style="margin-right: 10px">可单击输入框修改数值</span>
          <a-button style="margin: 10px 0" type="primary" @click="handleDownload" :loading="exportLoading">
            下载
          </a-button>
        </div>
        <a-table
          :scroll="{ y: 300 }"
          :loading="tableLoading"
          :columns="columns"
          :data-source="dataSource"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
          <div slot="submitStatus" slot-scope="text, record">
            {{ text === 'SUBMITTED' ? '已提交' : '未提交' }}
          </div>
          <div slot="ad_traffic_message" slot-scope="text, record">
            <div class="cell">
              <span>{{ handleData(record.submissionMaterial, '新增私信开口', 'ad_traffic_message') }}</span>
              <a-icon
                class="edit-icon"
                type="edit"
                @click="handleEdit(record.submissionMaterial, '新增私信开口', 'ad_traffic_message', record)"
              />
            </div>
          </div>
          <div slot="organic_traffic_message" slot-scope="text, record">
            <div class="cell">
              <span>{{ handleData(record.submissionMaterial, '新增私信开口', 'organic_traffic_message') }}</span>
              <a-icon
                class="edit-icon"
                type="edit"
                @click="handleEdit(record.submissionMaterial, '新增私信开口', 'organic_traffic_message', record)"
              />
            </div>
          </div>
          <div slot="ad_clue_count" slot-scope="text, record">
            <div class="cell">
              <span>{{ handleData(record.submissionMaterial, '新增线索', 'ad_clue_count') }}</span>
              <a-icon
                class="edit-icon"
                type="edit"
                @click="handleEdit(record.submissionMaterial, '新增线索', 'ad_clue_count', record)"
              />
            </div>
          </div>
          <div slot="organic_clue_count" slot-scope="text, record">
            <div class="cell">
              <span>{{ handleData(record.submissionMaterial, '新增线索', 'organic_clue_count') }}</span>
              <a-icon
                class="edit-icon"
                type="edit"
                @click="handleEdit(record.submissionMaterial, '新增线索', 'organic_clue_count')"
              />
            </div>
          </div>
          <div slot="store_visitor_num" slot-scope="text, record">
            <div class="cell">
              <span>{{ handleData(record.submissionMaterial, '新增客户', 'store_visitor_num') }}</span>
              <a-icon
                class="edit-icon"
                type="edit"
                @click="handleEdit(record.submissionMaterial, '新增客户', 'store_visitor_num', record)"
              />
            </div>
          </div>
          <div slot="order_num" slot-scope="text, record">
            <div class="cell">
              <span>{{ handleData(record.submissionMaterial, '新增客户', 'order_num') }}</span>
              <a-icon
                class="edit-icon"
                type="edit"
                @click="handleEdit(record.submissionMaterial, '新增客户', 'order_num', record)"
              />
            </div>
          </div>
        </a-table>
      </div>
      <a-modal :visible="visible" title="修改值" @ok="handleOk" @cancel="handleCancel">
        <a-input-number style="width: 100%" v-model="editValue" />
      </a-modal>
    </div>
  </div>
</template>

<script>
import xhsAgencyApi from '@/api/xhsAgencyApi';
import { TASK_TYPE_MAP, REPEAT_TYPE_MAP } from './data';
import moment from 'moment';
import { groupBy } from 'lodash';
import { downloadExcel } from '@/utils';
export default {
  name: 'dataCollectionDetail',
  components: {},
  data() {
    return {
      moment: moment,
      id: this.$route.query.id,
      detailData: {},
      dateMap: {},
      calendarValue: null,
      tableLoading: false,
      defaultColumns: [
        {
          title: '门店名称',
          dataIndex: 'memberName',
          key: 'memberName',
        },
        {
          title: '门店完成情况',
          dataIndex: 'submitStatus',
          key: 'submitStatus',
          scopedSlots: { customRender: 'submitStatus' },
        },
        {
          title: '小红书昵称',
          dataIndex: 'nickname',
          key: 'nickname',
        },
        {
          title: '投流开口',
          dataIndex: 'ad_traffic_message',
          key: 'ad_traffic_message',
          scopedSlots: { customRender: 'ad_traffic_message' },
        },
        {
          title: '自然流开口',
          dataIndex: 'organic_traffic_message',
          key: 'organic_traffic_message',
          scopedSlots: { customRender: 'organic_traffic_message' },
        },
        {
          title: '投流线索',
          dataIndex: 'ad_clue_count',
          key: 'ad_clue_count',
          scopedSlots: { customRender: 'ad_clue_count' },
        },
        {
          title: '自然流线索',
          dataIndex: 'organic_clue_count',
          key: 'organic_clue_count',
          scopedSlots: { customRender: 'organic_clue_count' },
        },
        {
          title: '到店人数',
          dataIndex: 'store_visitor_num',
          key: 'store_visitor_num',
          scopedSlots: { customRender: 'store_visitor_num' },
        },
        {
          title: '下单数',
          dataIndex: 'order_num',
          key: 'order_num',
          scopedSlots: { customRender: 'order_num' },
        },
      ],
      dataSource: [],
      dataTypeList: [],
      selectedData: {},
      selectedDate: '',
      editValue: '',
      editFirstCategory: '',
      editFieldName: '',
      visible: false,
      currentRow: {},
      exportLoading: false,
    };
  },
  computed: {
    columns() {
      const arr = [
        {
          title: '门店名称',
          dataIndex: 'memberName',
          key: 'memberName',
        },
        {
          title: '账号完成情况',
          dataIndex: 'submitStatus',
          key: 'submitStatus',
          width: 160,
          scopedSlots: { customRender: 'submitStatus' },
        },
        {
          title: '小红书昵称',
          dataIndex: 'nickname',
          key: 'nickname',
        },
      ];
      if (this.detailData.numFieldArr?.length) {
        this.detailData.numFieldArr.forEach((item) => {
          const findData = this.defaultColumns.find((el) => el.key === item);
          if (findData) {
            arr.push(findData);
          }
        });
      }
      return arr;
    },
    isRepeatEvery() {
      return this.detailData.taskCycleType === 'WORK_DAY_REPEAT';
    },
    sidebarList() {
      const { detailData, dataTypeList } = this;
      return [
        {
          title: '基本信息',
          children: [
            { text: '任务名称', value: detailData.taskName },
            { text: '任务时间', value: `${detailData.startTime}~${detailData.endTime}` },
          ],
        },
        {
          title: '任务规则',
          children: [
            { text: '任务类型', value: TASK_TYPE_MAP[detailData.taskType] },
            {
              text: '数值类型',
              value:
                dataTypeList.length && detailData.numFieldArr?.length
                  ? detailData.numFieldArr.map((item) => dataTypeList.find((el) => el.value === item).name).join('、')
                  : '',
            },
            { text: '每日重复', value: REPEAT_TYPE_MAP[detailData.taskCycleType] },
          ],
        },
        {
          title: '任务成员',
          children: [
            { text: '品牌', value: detailData.principalName },
            { text: '任务参与成员', value: detailData.memberList?.length },
          ],
        },
        {
          title: '任务结果',
          children: [
            {
              text: '任务参与率',
              value: this.currentParticipationCountRate,
            },
            {
              text: '应参与门店',
              value: this.currentParticipationCount,
            },
            {
              text: '参与门店',
              value: this.currentParticipatedCount,
            },
          ],
        },
      ];
    },

    taskDatetimeList() {
      return this.detailData.dateList?.map((v) => v.taskDatetime) || [];
    },
    currentParticipatedCount() {
      return this.isRepeatEvery
        ? this.selectedData?.participatedCount
        : this.detailData.dateList?.[0]?.participatedCount;
    },
    currentParticipationCount() {
      return this.isRepeatEvery
        ? this.selectedData?.participationCount
        : this.detailData.dateList?.[0]?.participationCount;
    },
    currentParticipationCountRate() {
      return !this.currentParticipationCount || !this.currentParticipatedCount
        ? '0%'
        : ((this.currentParticipatedCount / this.currentParticipationCount) * 100).toFixed() + '%';
    },
  },

  mounted() {
    this.getXhsTaskFieldMetadata();
    this.getDetail();
  },
  methods: {
    getDetail() {
      xhsAgencyApi.getXhsTaskFindDetailForNumApi(this.id).then((res) => {
        if (res.code === 200) {
          this.detailData = res.data;
          this.dateMap = groupBy(res.data.dateList, 'taskDatetime');
          this.calendarValue = moment(res.data.startTime);

          if (!this.isRepeatEvery) {
            this.getXhsTaskIdAndDate();
          }
        }
      });
    },
    getXhsTaskFieldMetadata() {
      xhsAgencyApi.getXhsTaskFieldMetadataApi().then((res) => {
        if (res.code === 200) {
          const list = [];
          res.data.forEach((item) => {
            item.fieldList.forEach((el) => {
              list.push({
                value: el.fieldName,
                name: el.fieldDescription,
              });
            });
          });
          this.dataTypeList = list;
        }
      });
    },
    getXhsTaskIdAndDate(date) {
      this.tableLoading = true;
      const params = {
        taskDate: date || '',
        taskId: this.detailData.id,
      };
      xhsAgencyApi
        .getXhsTaskIdAndDateApi(params)
        .then((res) => {
          if (res.code === 200) {
            this.dataSource = res.data;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleDate(date) {
      const dateFormat = moment(date).format('YYYY-MM-DD');
      return this.dateMap[dateFormat]?.[0]?.memberList || [];
    },
    handleSelect(date) {
      const dateFormat = moment(date).format('YYYY-MM-DD');
      this.selectedDate = dateFormat;
      this.selectedData = this.dateMap[dateFormat]?.[0] || [];
      if (this.isRepeatEvery) {
        this.getXhsTaskIdAndDate(dateFormat);
      }
    },
    handleData(submissionMaterial, firstCategory, fieldName) {
      if (submissionMaterial) {
        return (
          submissionMaterial
            ?.find((item) => item.firstCategory === firstCategory)
            ?.fieldList?.find((item) => item.fieldName === fieldName)?.fieldValue || '-'
        );
      }
      return '-';
    },
    handleEdit(submissionMaterial, firstCategory, fieldName, record) {
      if (submissionMaterial) {
        this.editValue =
          submissionMaterial
            ?.find((item) => item.firstCategory === firstCategory)
            ?.fieldList?.find((item) => item.fieldName === fieldName)?.fieldValue || '';
      } else {
        this.editValue = '';
      }
      this.editFirstCategory = firstCategory;
      this.editFieldName = fieldName;
      this.currentRow = record;
      this.visible = true;
    },
    handleOk() {
      if (!Number.isInteger(this.editValue)) {
        this.$message.warning('请输入整数');
        return;
      }
      this.currentRow.submissionMaterial.forEach((item) => {
        if (item.firstCategory) {
          item.fieldList.forEach((el) => {
            if (el.fieldName === this.editFieldName) {
              el.fieldValue = this.editValue;
            }
          });
        }
      });
      const payload = {
        id: this.currentRow.id,
        memberId: this.currentRow.memberId,
        submissionMaterial: this.currentRow.submissionMaterial,
      };
      xhsAgencyApi.putTaskSubmitNumApi(payload).then((res) => {
        if (res.code === 200) {
          this.getXhsTaskIdAndDate(this.selectedDate);
        }
      });
      this.visible = false;
    },

    handleCancel() {
      this.visible = false;
      this.editValue = '';
    },
    async handleDownload() {
      this.exportLoading = true;
      const res = await xhsAgencyApi
        .exportXhsTaskApi({ id: this.id })
        .finally(() => (this.exportLoading = false));
      downloadExcel(res, '任务数据');
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  // height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.left-sidebar {
  flex-basis: 250px;
  padding: 20px;
  box-sizing: border-box;
}

.right-content {
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d0d0d0;
}

/* 右侧内容的顶部和底部模块 */
.top-module {
  /deep/ .ant-radio-group {
    display: none;
  }
  /deep/ .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
    height: 90px;
  }
  /deep/ .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    height: 60px;
  }
}
.bottom-module {
  flex: 1;
  overflow-y: auto;
}

.content {
  display: flex;
  flex-direction: column;
  .item {
    margin-bottom: 20px;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: -15px;
  }
  .text {
    margin-bottom: 5px;
    .text-title {
      font-weight: bold;
    }
    .text-value {
      font-size: 13px;
    }
  }
}

.cell {
  &:hover {
    .edit-icon {
      display: inline-block;
    }
  }
}
.edit-icon {
  display: none;
  cursor: pointer;
}
</style>
